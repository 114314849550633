<template>
  <div>
    <button
      type="button"
      class="btn btn-primary"
      id="button-open-dialog"
      data-bs-toggle="modal"
      data-bs-target="#kt_modal_1"
      hidden
    ></button>
    <div
      class="modal fade"
      tabindex="-1"
      id="kt_modal_1"
      data-bs-backdrop="static"
    >
      <div class="modal-dialog modal-md" role="document">
        <div class="modal-content">
          <div class="d-flex justify-content-between">
            <h5 class="modal-title"></h5>
            <button
              data-bs-dismiss="modal"
              @click="close"
              type="button"
              class="btn btn-sm"
            >
              <i
                class="bi bi-x m-0 p-0"
                id="close-btn"
                style="font-size: 25px"
              ></i>
            </button>
          </div>

          <div class="modal-body mt-0 pt-0">
            <form autocomplete="off">
              <div class="row d-flex justify-content-center mb-8">
                <i
                  style="font-size: 60px; color: DimGrey"
                  class="bi bi-exclamation-circle text-center mb-6"
                ></i>
                <div
                  class="col-sm-10 text-center"
                  style="color: grey; font-size: 18px"
                >
                  โปรดใส่รหัส <b style="color: DimGrey">6</b> หลัก เพื่อทำการ
                  <b style="color: DimGrey"><u>อนุมัติ</u></b> รายการ
                </div>
              </div>
              <div class="row d-flex justify-content-center mb-4 mx-3">
                <div class="col-2">
                  <input
                    v-model="form.pass1"
                    @keyup.delete="atKeydownDelete"
                    @keydown="checkKeypress"
                    @focus="focusInput = 1"
                    :type="typePass1"
                    maxlength="1"
                    ref="pass1"
                    id="pass1"
                    class="form-control"
                    :style="cssPass1"
                  />
                </div>
                <div class="col-2">
                  <input
                    v-model="form.pass2"
                    :type="typePass2"
                    @keyup.delete="atKeydownDelete"
                    @keydown="checkKeypress"
                    @focus="focusInput = 2"
                    maxlength="1"
                    ref="pass2"
                    id="pass2"
                    class="form-control"
                    :style="cssPass2"
                  />
                </div>
                <div class="col-2">
                  <input
                    v-model="form.pass3"
                    :type="typePass3"
                    @keyup.delete="atKeydownDelete"
                    @keydown="checkKeypress"
                    @focus="focusInput = 3"
                    maxlength="1"
                    ref="pass3"
                    id="pass3"
                    class="form-control"
                    :style="cssPass3"
                  />
                </div>
                <div class="col-2">
                  <input
                    v-model="form.pass4"
                    :type="typePass4"
                    @keyup.delete="atKeydownDelete"
                    @keydown="checkKeypress"
                    @focus="focusInput = 4"
                    maxlength="1"
                    ref="pass4"
                    id="pass4"
                    class="form-control"
                    :style="cssPass4"
                  />
                </div>
                <div class="col-2">
                  <input
                    v-model="form.pass5"
                    :type="typePass5"
                    @keyup.delete="atKeydownDelete"
                    @keydown="checkKeypress"
                    @focus="focusInput = 5"
                    maxlength="1"
                    ref="pass5"
                    id="pass5"
                    class="form-control"
                    :style="cssPass5"
                  />
                </div>
                <div class="col-2">
                  <input
                    v-model="form.pass6"
                    :type="typePass6"
                    @keyup.delete="atKeydownDelete"
                    @keydown="checkKeypress"
                    @focus="focusInput = 6"
                    maxlength="1"
                    ref="pass6"
                    id="pass6"
                    class="form-control"
                    :style="cssPass6"
                  />
                </div>

                <div
                  v-if="valid"
                  class="d-flex justify-content-center"
                  style="color: red"
                >
                  กรุณากรอกรหัสให้ถูกต้อง
                </div>

                <div class="row mt-10 text-start fadeIn fourth">
                  <div class="form-switch">
                    <input
                      v-model="showPassword"
                      class="form-check-input me-2"
                      type="checkbox"
                      id="flexSwitchCheckDefault"
                    />
                    Show Password
                  </div>
                </div>
              </div>
            </form>
          </div>

          <footer class="pb-10">
            <div class="row">
              <div class="col-12 ms-13">
                <button
                  @click="submit"
                  type="button"
                  class="btn btn-primary me-3"
                  data-bs-dismiss="modal"
                  :disabled="loadingUpdate"
                >
                  <div
                    v-if="loadingUpdate"
                    class="spinner-grow spinner-grow-sm text-light"
                    role="status"
                  ></div>
                  อนุมัติ
                </button>
                <!-- <button
                  @click="disapproved"
                  type="button"
                  class="btn btn-danger me-3"
                  data-bs-dismiss="modal"
                  :disabled="loadingUpdate"
                >
                  <div
                    v-if="loadingUpdate"
                    class="spinner-grow spinner-grow-sm text-light"
                    role="status"
                  ></div>
                  ไม่อนุมัติ
                </button> -->
                <button
                  @click="close"
                  type="button"
                  class="btn btn-light"
                  data-bs-dismiss="modal"
                >
                  ยกเลิก
                </button>
              </div>
            </div>
          </footer>
          <!-- <div class="modal-footer d-flex justify-content-start">
            <hr class="pt-0 mt-0" style="color: LightGrey" />
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Decode, Encode } from "@/services";
import loginApi from "@/api/user";
import userApi from "@/api/user/";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default {
  props: {
    dialogConfirmByPass: Boolean,
    loadingUpdate: Boolean,
  },
  data: () => ({
    isSubmit: false,
    valid: false,
    showPassword: false,

    userId: "",
    userName: "",

    focusInput: null,

    form: {
      pass1: "",
      pass2: "",
      pass3: "",
      pass4: "",
      pass5: "",
      pass6: "",
    },

    typePass1: "password",
    typePass2: "password",
    typePass3: "password",
    typePass4: "password",
    typePass5: "password",
    typePass6: "password",

    cssPass1: "text-align: center; direction: rtl",
    cssPass2: "text-align: center; direction: rtl",
    cssPass3: "text-align: center; direction: rtl",
    cssPass4: "text-align: center; direction: rtl",
    cssPass5: "text-align: center; direction: rtl",
    cssPass6: "text-align: center; direction: rtl",
  }),
  watch: {
    dialogConfirmByPass(val) {
      if (val) {
        document.getElementById("button-open-dialog").click();
        this.getAllUser();
        setTimeout(() => {
          this.$refs.pass1.focus();
        }, 500);
      }
    },

    "form.pass1"(val) {
      if (val) {
        this.$refs.pass2.focus();
      }
    },
    "form.pass2"(val) {
      if (val) {
        this.$refs.pass3.focus();
      } else {
        this.$refs.pass1.focus();
      }
    },
    "form.pass3"(val) {
      if (val) {
        this.$refs.pass4.focus();
      } else {
        this.$refs.pass2.focus();
      }
    },
    "form.pass4"(val) {
      if (val) {
        this.$refs.pass5.focus();
      } else {
        this.$refs.pass3.focus();
      }
    },
    "form.pass5"(val) {
      if (val) {
        this.$refs.pass6.focus();
      } else {
        this.$refs.pass4.focus();
      }
    },
    "form.pass6"(val) {
      if (!val) {
        this.$refs.pass5.focus();
      }
    },

    showPassword(val) {
      this.checkFocusInput();
      if (val) {
        this.typePass1 = "text";
        this.typePass2 = "text";
        this.typePass3 = "text";
        this.typePass4 = "text";
        this.typePass5 = "text";
        this.typePass6 = "text";
      } else {
        this.typePass1 = "password";
        this.typePass2 = "password";
        this.typePass3 = "password";
        this.typePass4 = "password";
        this.typePass5 = "password";
        this.typePass6 = "password";
      }
    },
  },
  methods: {
    async getAllUser() {
      this.userId = Decode.decode(localStorage.getItem("boss"));
      let responseData = [];

      try {
        responseData = await userApi.user.getAll("confirm");
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        const users = responseData.data;
        const findUser = users.find(
          (data) => data.id === parseInt(this.userId)
        );
        if (findUser) {
          this.userName = findUser.username;
        }
      }
    },
    async submit() {
      let confirmPass = `${this.form.pass1}${this.form.pass2}${this.form.pass3}${this.form.pass4}${this.form.pass5}${this.form.pass6}`;
      let loginResponse = [];
      try {
        loginResponse = await userApi.user.approval({
          username: this.userName,
          password: confirmPass,
        });
      } catch (err) {
        console.log(err);
      }
      if (loginResponse.response_status === "SUCCESS") {
        this.clear();
        this.$emit("submitConfirmPass", true);
      } else {
        Swal.fire({
          icon: "error",
          title: "รหัสผ่านผิด !",
          html: `<div style="font-size:16px">Username : <b>${this.userName}</b> <br><br>รหัสผ่านไม่ถูกต้อง</div>`,
          confirmButtonText: "ตกลง",
        }).then(() => {
          this.close();
        });
      }
    },
    async disapproved() {
      let confirmPass = `${this.form.pass1}${this.form.pass2}${this.form.pass3}${this.form.pass4}${this.form.pass5}${this.form.pass6}`;
      let loginResponse = [];
      try {
        loginResponse = await userApi.user.approval({
          username: this.userName,
          password: confirmPass,
        });
      } catch (err) {
        console.log(err);
      }
      if (loginResponse.response_status === "SUCCESS") {
        this.$emit("submitConfirmPass", false);
      } else {
        Swal.fire({
          icon: "error",
          title: "รหัสผ่านผิด !",
          html: `<div style="font-size:16px">Username : <b>${this.userName}</b> <br><br>รหัสผ่านไม่ถูกต้อง</div>`,
          confirmButtonText: "ตกลง",
        }).then(() => {
          this.close();
        });
      }
    },
    close() {
      this.$emit("closeDialogConformByPass");
      this.clear();
    },
    clear() {
      this.form = {
        pass1: "",
        pass2: "",
        pass3: "",
        pass4: "",
        pass5: "",
        pass6: "",
      };
    },
    atKeydownDelete() {
      if (this.focusInput === 2) {
        if (this.form.pass2) {
          this.$refs.pass2.focus();
        } else {
          this.$refs.pass1.focus();
        }
      }
      if (this.focusInput === 3) {
        if (this.form.pass3) {
          this.$refs.pass3.focus();
        } else {
          this.$refs.pass2.focus();
        }
      }
      if (this.focusInput === 4) {
        if (this.form.pass4) {
          this.$refs.pass4.focus();
        } else {
          this.$refs.pass3.focus();
        }
      }
      if (this.focusInput === 5) {
        if (this.form.pass5) {
          this.$refs.pass5.focus();
        } else {
          this.$refs.pass4.focus();
        }
      }
      if (this.focusInput === 6) {
        this.$refs.pass5.focus();
      }
    },
    checkKeypress() {
      if (window.event.key === "ArrowLeft") {
        if (this.focusInput === 2) {
          const input = document.getElementById("pass1");

          if (!this.showPassword) {
            this.typePass1 = "text";
            this.typePass2 = "password";
          }

          this.$refs.pass1.focus();

          input.focus();
          input.setSelectionRange(0, 1);

          setTimeout(() => {
            this.cssPass1 = "text-align: center;";
            input.setSelectionRange(0, 1);
          }, 200);
        } else if (this.focusInput === 3) {
          const input = document.getElementById("pass2");

          if (!this.showPassword) {
            this.typePass2 = "text";
            this.typePass3 = "password";
          }

          this.$refs.pass2.focus();

          input.focus();
          input.setSelectionRange(0, 1);

          setTimeout(() => {
            this.cssPass2 = "text-align: center;";
            input.setSelectionRange(0, 1);
          }, 200);
        } else if (this.focusInput === 4) {
          const input = document.getElementById("pass3");

          if (!this.showPassword) {
            this.typePass3 = "text";
            this.typePass4 = "password";
          }

          this.$refs.pass3.focus();

          input.focus();
          input.setSelectionRange(0, 1);

          setTimeout(() => {
            this.cssPass3 = "text-align: center;";
            input.setSelectionRange(0, 1);
          }, 200);
        } else if (this.focusInput === 5) {
          const input = document.getElementById("pass4");

          if (!this.showPassword) {
            this.typePass4 = "text";
            this.typePass5 = "password";
          }

          this.$refs.pass4.focus();

          input.focus();
          input.setSelectionRange(0, 1);

          setTimeout(() => {
            this.cssPass4 = "text-align: center;";
            input.setSelectionRange(0, 1);
          }, 200);
        } else if (this.focusInput === 6) {
          const input = document.getElementById("pass5");

          if (!this.showPassword) {
            this.typePass5 = "text";
            this.typePass6 = "password";
          }

          this.$refs.pass5.focus();

          input.focus();
          input.setSelectionRange(0, 1);

          setTimeout(() => {
            this.cssPass5 = "text-align: center;";
            input.setSelectionRange(0, 1);
          }, 200);
        }
      }
      if (window.event.key === "ArrowRight") {
        if (this.focusInput === 1) {
          if (!this.showPassword) {
            this.typePass1 = "password";
            this.typePass2 = "text";
          }

          this.$refs.pass2.focus();
        } else if (this.focusInput === 2) {
          if (!this.showPassword) {
            this.typePass2 = "password";
            this.typePass3 = "text";
          }

          this.$refs.pass3.focus();
        } else if (this.focusInput === 3) {
          if (!this.showPassword) {
            this.typePass3 = "password";
            this.typePass4 = "text";
          }

          this.$refs.pass4.focus();
        } else if (this.focusInput === 4) {
          if (!this.showPassword) {
            this.typePass4 = "password";
            this.typePass5 = "text";
          }

          this.$refs.pass5.focus();
        } else if (this.focusInput === 5) {
          if (!this.showPassword) {
            this.typePass5 = "password";
            this.typePass6 = "text";
          }

          this.$refs.pass6.focus();
        }
      }
    },

    checkFocusInput() {
      if (this.focusInput === 1) {
        this.$refs.pass1.focus();
      }
      if (this.focusInput === 2) {
        this.$refs.pass2.focus();
      }
      if (this.focusInput === 3) {
        this.$refs.pass3.focus();
      }
      if (this.focusInput === 4) {
        this.$refs.pass4.focus();
      }
      if (this.focusInput === 5) {
        this.$refs.pass5.focus();
      }
      if (this.focusInput === 6) {
        this.$refs.pass6.focus();
      }
    },
  },
};
</script>

<style scoped>
@media only screen and (max-width: 600px) {
  .display-phone {
    text-align: left !important;
  }
}
#close-btn:hover {
  background-color: LightGrey;
  border-radius: 25px;
  color: white;
}
</style>
