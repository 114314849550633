<template>
  <div v-if="loading">
    <Loader />
  </div>
  <div class="card" v-if="!loading">
    <div class="card-header">
      <h3 class="card-title" style="font-size: 20px; font-weight: bold">
        Product Library : WH4
      </h3>
      <div class="card-toolbar">
        <button
          type="button"
          class="btn btn-sm btn-light-primary btn-hover-scale me-2"
          style="font-size: 14px; font-weight: bold; height: 40px"
          @click="gotoExportFile()"
          @mouseenter="hoverABtn"
        >
          <i style="font-size: 16px" class="bi bi-box-arrow-up"></i>
          Export
        </button>

        <div>
          <DialogExportFile
            :dialogExportFile="dialogExportFile"
            @closeDialogExportFile="closeDialogExportFile"
            @selectedTypeExportFile="selectedTypeExportFile"
          />
        </div>
        <button
          type="button"
          class="btn btn-sm btn-light btn-primary"
          style="font-size: 14px; font-weight: bold"
          @click="goToNew()"
        >
          <span class="svg-icon svg-icon-2x">
            <inline-svg src="media/Flatten.svg" /> </span
          >เพิ่มข้อมูล
        </button>
      </div>
    </div>
    <div class="card-title row ms-5 me-5 mt-6">
      <div class="col-sm-3 mb-4">
        <input
          v-model="searchInput"
          placeholder="ค้นหา"
          type="text"
          class="form-control"
        />
      </div>
      <div class="col-sm-3 mb-3">
        <select v-model="searchBy" class="form-select">
          <option value="1">ตามรหัสสินค้า</option>
          <option value="2">ตาม Barcode</option>
          <option value="3">ตาม POG</option>
        </select>
      </div>
      <div class="col-sm-1 text-end">
        <button
          @click="search()"
          type="button"
          class="btn btn-sm btn-light-primary"
          style="font-size: 14px; font-weight: bold"
        >
          ค้นหา
        </button>
      </div>
    </div>
    <div class="card-body" style="padding-top: 3px">
      <div class="table-responsive">
        <table
          class="table table-hover table-rounded table-striped border gy-7 gs-7"
        >
          <thead>
            <tr
              style="width: 100%"
              class="fw-bold fs-5 text-gray-800 border-bottom-2 border-gray-200 mw-200px"
            >
              <th class="text-center">#</th>
              <th class="text-center">รหัสสินค้า</th>
              <th class="text-center">ชื่อสินค้า</th>
              <th class="text-center">Barcode</th>
              <th class="text-center">POG</th>
              <th class="text-center">ทุน</th>
              <th class="text-center">ขายส่ง</th>
              <th class="text-center">ขายปลีก</th>
              <th class="text-center">ขาย Pro</th>
              <th class="text-center">SOH</th>
              <th class="text-center">In Transit</th>
              <th class="text-center">Min</th>
              <th class="text-center">Max</th>
              <th class="text-center">Status</th>
              <th class="text-center"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="dataItemsShow.length < 1">
              <td class="text-center" colspan="15">ไม่มีข้อมูล</td>
            </tr>
            <tr v-for="(item, index) in dataItemsShow" :key="index">
              <td>{{ !showing1 ? index + 1 : showing1 + index }}</td>
              <td>{{ item.product_code }}</td>
              <td class="fs-5 fw-500">
                {{ item.product_name }}
              </td>
              <td>{{ item.product_barcode }}</td>
              <td>{{ item.product_pog }}</td>
              <td class="text-end">
                {{
                  formatPrice(
                    item.product_cost_price ? item.product_cost_price : 0
                  )
                }}
              </td>
              <td class="text-end">
                {{
                  formatPrice(
                    item.product_wholesale_price
                      ? item.product_wholesale_price
                      : 0
                  )
                }}
              </td>
              <td class="text-end">
                {{
                  formatPrice(
                    item.product_retail_price ? item.product_retail_price : 0
                  )
                }}
              </td>
              <td class="text-end">
                {{
                  formatPrice(
                    item.product_promotion_price
                      ? item.product_promotion_price
                      : 0
                  )
                }}
              </td>
              <td class="text-end">{{ item.product_bring_qty }}</td>
              <td class="text-end">{{ item.product_in_transit }}</td>
              <td class="text-end">{{ item.product_min_stock }}</td>
              <td class="text-end">{{ item.product_max_stock }}</td>
              <td
                :style="
                  item.is_cancel === '0'
                    ? 'color: #3ca76c; font-weight: bold'
                    : 'color: FireBrick; font-weight: bold'
                "
                class="text-center"
              >
                {{ item.is_cancel === "0" ? "ใช้งาน" : "ยกเลิกใช้งาน" }}
              </td>

              <td>
                <div class="row">
                  <div class="col-sm-6">
                    <button
                      type="button"
                      class="btn btn-link btn-color-muted btn-active-color-success p-0"
                      @click="goToEdit(item.id)"
                    >
                      <i class="bi bi-pencil-square"></i>
                    </button>
                  </div>
                  <div class="col-sm-6">
                    <button
                      @mouseenter="hoverBBtn"
                      type="button"
                      class="btn btn-link btn-color-muted btn-active-color-danger p-0"
                      @click="goToDelete(item, index)"
                    >
                      <i class="bi bi-trash3-fill"></i>
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div v-if="hoverB">
      <DialogConfirmByPass
        :dialogConfirmByPass="dialogConfirmByPass"
        :loadingUpdate="loadingUpdate"
        @submitConfirmPass="submitConfirmPass"
        @closeDialogConformByPass="closeDialogConformByPass"
      />
    </div>

    <div v-if="isExcel">
      <ExportExcelFile :dataExportExcel="dataExportExcel" />
    </div>

    <div v-if="isPdf">
      <ExportPdfFile :dataExportPdf="dataExportPdf" />
    </div>

    <div class="card-footer">
      <Pagination
        :items="tableItems"
        @filterItems="filterItems"
        @handleChangePage="handleChangePage"
      />
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import whApi from "@/api/warehouse/";

import DialogConfirmByPass from "./components/dialogConfirmByPass.vue";

import DialogExportFile from "./components/dialogExportFile.vue";
import ExportExcelFile from "./components/exportFileExcel.vue";
import ExportPdfFile from "./components/exportFilePdf.vue";

import Pagination from "../../components/pagination.vue";
import Loader from "../../components/loaderIndex.vue";
import Search from "../../components/search.vue";

import numbro from "numbro";

export default {
  components: {
    DialogExportFile,
    ExportExcelFile,
    ExportPdfFile,
    DialogExportFile,
    DialogConfirmByPass,
    Pagination,
    Search,
    Loader,
  },
  setup() {
    document.title = "BELUCA | Product Library";
  },
  data: () => ({
    loading: false,
    loadingUpdate: false,
    dialogImportExcel: false,

    dialogExportFile: false,

    dataExportExcel: [],
    isExcel: false,

    dataExportPdf: [],
    isPdf: false,

    dialogConfirmByPass: false,

    showing1: null,

    hoverA: false,
    hoverB: false,

    dataItemsShow: [],
    tableItems: [],
    itemApprove: {},

    searchInput: "",
    searchBy: "1",
    allData: [],
  }),

  created() {
    this.getAll();
  },

  methods: {
    async getAll(method) {
      this.loading = true;
      let responseData = [];
      const companyId = localStorage.getItem("companyId");
      const companyBranchId = localStorage.getItem("companyBranchId");

      try {
        responseData = await whApi.product.getAll({
          companyId: companyId,
          companyBranchId: companyBranchId,
        });
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.dataExportExcel = responseData.data;
        this.dataExportPdf = responseData.data;

        this.dataItemsShow = responseData.data;
        this.tableItems = responseData.data;
        this.allData = responseData.data;
        this.loading = false;
        if (method === 1) {
          await this.$router.push({
            query: {
              page: this.$route.query.page,
              get: 1,
            },
          });
        }
      } else {
        this.loading = false;
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ดึงข้อมูลไม่สำเร็จ กรุณาตรวจสอบ SERVER.",
        });
      }
    },

    hoverABtn() {
      this.hoverA = true;
      this.hoverB = false;
    },
    hoverBBtn() {
      this.hoverB = true;
      this.hoverA = false;
    },

    //--- Pagination --- start
    filterItems(val) {
      this.dataItemsShow = val;
    },

    handleChangePage(val, showing1) {
      this.dataItemsShow = val;
      this.showing1 = showing1;
    },
    //--- Pagination --- end

    goToNew() {
      this.$router.push({
        path: "/warehouse/WH4/new",
        query: {},
      });
    },

    goToEdit(id) {
      this.$router.push({
        name: "WH4-edit",
        query: {
          id: id,
        },
      });
    },
    gotoImportExcel() {
      this.dialogImportExcel = true;
    },

    //export file excel, pdf
    gotoExportFile() {
      this.dialogExportFile = true;
      this.isExcel = false;
      this.isPdf = false;
    },

    closeDialogExportFile() {
      this.dialogExportFile = false;
    },

    selectedTypeExportFile(val) {
      this.dialogExportFile = false;
      if (val === "excel") {
        this.isExcel = true;
      } else {
        this.isPdf = true;
      }
    },
    //export file excel, pdf

    async goToDelete(item, index) {
      console.log(item);
      if (item.product_bring_qty > 0) {
        Swal.fire({
          icon: "warning",
          title:
            "<span style='font-size: 20px'>ไม่สามารถลบสินค้ารายการนี้ได้</span>",
          html: "<span>ยังมีสินค้าคงเหลือในคลังสินค้าไม่สามารถลบสินค้ารายการนี้ออกจากระบบได้ <strong style='font-style: italic; font-size: 15px'>โปรดทำการขายสินค้า โอน หรือ ปรับ ปรุงคลัง สินค้า เพื่อให้สินค้า เป็น 0</strong> ก่อนทำการลบสินค้ารายการนี้ออกจากระบบ</span>",
          confirmButtonText: "ตกลง",
          confirmButtonColor: "#f1416c",
        });
      } else {
        Swal.fire({
          icon: "question",
          title: `ต้องการลบ ${item.product_name} หรือไม่ ?`,
          confirmButtonText: "ตกลง",
          cancelButtonText: "ยกเลิก",
          showCancelButton: true,
        }).then(async (result) => {
          if (result.isConfirmed) {
            this.confirmDelete(item);
          }
        });
      }
    },
    async confirmDelete(item) {
      let deleteResponse = [];
      const findIndex = this.dataItemsShow.indexOf(item);

      // deleteResponse = await whApi.product.delete(item.id);
      deleteResponse = await whApi.product.update(item.id, {
        is_cancel: "1",
        isUpdateQty: true,
      });
      if (deleteResponse.response_status === "SUCCESS") {
        Swal.fire({
          icon: "success",
          title: "ลบสำเร็จ",
          showConfirmButton: false,
          timer: 1500,
        }).then(() => {
          this.dataItemsShow.splice(findIndex, 1);
          this.tableItems.splice(findIndex, 1);
          this.getAll(1);
        });
      }
    },
    async statusApprove(isApprove) {
      if (isApprove) {
        this.confirmDelete();
      }
    },
    handleApprove(item) {
      this.itemApprove = { ...item };
      this.dialogConfirmByPass = true;
    },
    async submitConfirmPass(isApprove) {
      this.statusApprove(isApprove);
      this.dialogConfirmByPass = false;
    },
    closeDialogConformByPass() {
      this.dialogConfirmByPass = false;
    },
    closeDialogImportExcel() {
      this.dialogImportExcel = false;
    },
    textSearch(val) {
      console.log({ val });
    },
    async search() {
      this.isExcel = false;
      this.isPdf = false;
      const companyId = localStorage.getItem("companyId");
      const companyBranchId = localStorage.getItem("companyBranchId");

      this.loading = true;

      let responseSearch = [];

      if (this.searchBy === "1") {
        // responseSearch = await whApi.product.search(
        //   {
        //     searchByCode: this.searchInput,
        //   },
        //   "0"
        // );
        responseSearch = await whApi.product.search(
          {
            searchByCode: this.searchInput,
          },
          {
            companyId: companyId,
            companyBranchId: companyBranchId,
            pos: "0",
          }
        );
      }

      if (this.searchBy === "2") {
        // responseSearch = await whApi.product.search(
        //   {
        //     searchByBarcode: this.searchInput,
        //   },
        //   "0"
        // );
        responseSearch = await whApi.product.search(
          {
            searchByBarcode: this.searchInput,
          },
          {
            companyId: companyId,
            companyBranchId: companyBranchId,
            pos: "0",
          }
        );
      }
      if (this.searchBy === "3") {
        // responseSearch = await whApi.product.search(
        //   {
        //     searchByPog: this.searchInput,
        //   },
        //   "0"
        // );
        responseSearch = await whApi.product.search(
          {
            searchByPog: this.searchInput,
          },
          {
            companyId: companyId,
            companyBranchId: companyBranchId,
            pos: "0",
          }
        );
      }

      if (responseSearch.response_status === "SUCCESS") {
        this.dataExportPdf = responseSearch.data;

        this.dataItemsShow = responseSearch.data;
        this.tableItems = responseSearch.data;
        this.allData = responseSearch.data;
        this.loading = false;
      }
    },

    formatPrice(val) {
      if (val) {
        const newVal = numbro(val).format({
          thousandSeparated: true,
          mantissa: 2,
        });
        return newVal;
      } else {
        const newVal = numbro(0).format({
          thousandSeparated: true,
          mantissa: 2,
        });
        return newVal;
      }
    },
  },
};
</script>
