<template>
  <div>
    <button
      type="button"
      class="btn btn-primary"
      id="button-open-dialog"
      data-bs-toggle="modal"
      data-bs-target="#kt_modal_1"
      hidden
    ></button>
    <div class="modal fade" tabindex="-1" id="kt_modal_1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Export Product Library</h5>

            <div
              class="btn btn-icon btn-sm btn-active-light-primary ms-2"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span class="svg-icon svg-icon-2x"></span>
            </div>
          </div>

          <div class="modal-body">
            <div class="input-group row mb-3">
              <div class="custom-file col-sm-12">
                <label class="form-label">Select Export Format : </label>
                <select class="form-select" v-model="form.selectTypeFile">
                  <option value="excel">Excel</option>
                  <option value="pdf">PDF</option>
                </select>
              </div>
            </div>
          </div>

          <div class="modal-footer d-flex justify-content-start">
            <button
              @click="submit"
              type="button"
              class="btn btn-primary"
              data-bs-dismiss="modal"
            >
              Submit
            </button>
            <button
              @click="close"
              type="button"
              class="btn btn-light"
              data-bs-dismiss="modal"
            >
              Discard
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dialogExportFile: Boolean,
  },
  data: () => ({
    form: {
      selectTypeFile: "excel",
    },
  }),
  watch: {
    dialogExportFile(val) {
      if (val) {
        document.getElementById("button-open-dialog").click();
      }
    },
  },
  methods: {
    submit() {
      this.$emit("selectedTypeExportFile", this.form.selectTypeFile);
    },
    close() {
      this.$emit("closeDialogExportFile");
    },
  },
};
</script>
